<template>
    <div v-if="$store.getters.settings">
        <div class="field grid mb-4"
             v-for="field in fields"
             v-if="field.key !== 'range' || searchPeriod === 'period'"
        >
            <label class="col-fixed text-700 mt-1 w-4">
                {{ field.label }}
            </label>
            <div class="col">
                <div class="p-inputgroup w-6" v-if="field.key === 'maximalDistance'">
                    <input-number v-model="value[field['key']]" class="w-10"/>
                    <span class="p-inputgroup-addon w-2 text-center">km</span>
                </div>
                <Calendar
                    v-if="field.key === 'range'"
                    :min-date="minDate"
                    v-model="range"
                    :inline="false"
                    selectionMode="range"
                    class="w-6"
                />
                <Multiselect
                    v-if="field.key === 'placeTypes'"
                    class="w-6"
                    :options="field.options"
                    v-model="value[field['key']]"
                    option-value="id"
                    option-label="label"
                />
                <SelectButton
                    class="w-6"
                    v-if="field.key === 'searchPeriod'"
                    v-model="searchPeriod"
                    :options="[{label: 'Ab sofort', id: 'now'}, {label: 'Zeitraum', id: 'period'}]"
                    option-label="label"
                    option-value="id"
                />
                <InputNumber
                    class="w-6"
                    v-if="field.key === 'costs' || field.key === 'budget'"
                    v-model="value[field['key']]"
                    mode="currency"
                    currency="EUR"
                    locale="de-DE"
                />
                <InputNumber
                    class="w-6"
                    v-if="field.key === 'attendances'"
                    v-model="value[field['key']]"
                    :min="0"
                />
                <Dropdown
                    v-if="field.type === 'Dropdown'"
                    class="w-6"
                    :options="getFields(field['optionKey'])"
                    option-label="label"
                    option-value="id"
                    v-model="value[field['key']]"
                />
                <Multiselect
                    v-if="field.type === 'Multiselect'"
                    class="w-6"
                    :options="getFields(field['optionKey'])"
                    v-model="value[field['key']]"
                    option-label="label"
                    option-value="id"
                />

                <errors :errors="errors[field['key']]"/>

                <div v-if="field.key === 'units'" class="grid w-6">
                    <div class="col col-3">
                        <input-number
                            v-model="unitCount"/>
                    </div>
                    <div class="col col-9">
                        <Dropdown
                            :options="unitTypeOptions"
                            v-model="unitType"
                            option-value="id"
                            option-label="label"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Value from "@/mixin/Value";
    import Errors from "@/components/form/Errors";
    import Dropdown from "primevue/dropdown/Dropdown";
    import InputNumber from "primevue/inputnumber";
    import Multiselect from "primevue/multiselect";
    import Calendar from "primevue/calendar/Calendar";
    import SelectButton from "primevue/selectbutton/SelectButton";

    export default {
        validations: {},
        props: {
            isOffer: {
                type: Boolean,
                default: () => true
            }
        },
        components: {
            Errors,
            Dropdown,
            Multiselect,
            InputNumber,
            SelectButton,
            Calendar
        },
        computed: {
            minDate: function () {
                let minDate = new Date();
                minDate.setFullYear(minDate.getFullYear() - 1);
                return minDate;
            },
            fields() {
                let baseFields = [
                    {
                        key: 'groupSize',
                        label: 'Gruppengröße',
                        type: 'Dropdown',
                        optionKey: 'groupSize',
                    },
                    {
                        key: 'participantAgeGroups',
                        label: 'Altersgruppe',
                        type: 'Multiselect',
                        optionKey: 'participantAgeGroup',
                    },
                    {
                        key: 'participantGenders',
                        label: 'Geschlecht',
                        type: 'Multiselect',
                        optionKey: 'participantGender',
                    },
                    {
                        key: 'participantLevels',
                        label: 'Ebene / Hierarchie',
                        type: 'Multiselect',
                        optionKey: 'participantLevels',
                    },
                ];

                if (this.isOffer) {
                    return baseFields.concat([
                        {
                            key: 'costs',
                            label: 'Kosten',
                            type: 'Number',
                        },
                        {
                            key: 'units',
                            label: 'Einheiten',
                            optionKey: 'units',
                        }
                    ]);
                } else {
                    return [
                        {
                            key: 'attendances',
                            label: 'Anzahl Personen',
                        },
                        {
                            key: 'budget',
                            label: 'Budget',
                            type: 'Currency',
                        },
                        {
                            key: 'searchPeriod',
                            label: 'Zeitraum',
                        },
                        {
                            key: 'range',
                            label: 'Zeitspanne'
                        },
                        {
                            key: 'placeTypes',
                            label: 'Standort',
                            options: Object
                                .entries(this.$store.getters.settings.model.request.placeType)
                                .map(([key, value]) => ({id: key, label: value}))
                        },
                        {
                            key: 'maximalDistance',
                            label: 'Maximale Entfernung'
                        }
                    ].concat(baseFields);
                }
            }
        },
        mixins: [Value],
        mounted() {
            if (this.value.hasOwnProperty('id')) {
                this.range[0] = this.value.start;
                this.range[1] = this.value.end;
                this.$forceUpdate();
            }

            if(this.value.hasOwnProperty('units')) {
                let units = this.value.units;
                if(units % 8 === 0) {
                    this.unitType = 'day';
                    this.unitCount = units/8;
                } else if(units % 4 === 0) {
                    this.unitType = 'half';
                    this.unitCount = units/4;
                } else {
                    this.unitType = 'hour';
                    this.unitCount = units;
                }
            } else if (!this.value.hasOwnProperty('unitType')) {
                this.value.unitType = 'day';
                this.value.units = 8;
                this.$emit('input', this.value);
            }
        },
        methods: {
            getFields(name) {
                return Object
                    .entries(this.$store.getters.settings.model.offer[name])
                    .map(([key, value]) => ({id: key, label: value}));
            }
        },
        watch: {
            range: {
                handler: function (newVal) {
                    this.value['start'] = newVal[0];
                    this.value['end'] = newVal[1];
                    this.$emit('input', this.value);
                },
                deep: true
            },
            searchPeriod: function (newVal) {
                if (newVal === 'now') {
                    this.value['start'] = null;
                    this.value['end'] = null;
                    this.$emit('input', this.value);
                }
            },
            unitCount: function (newVal) {
                if (this.unitType === 'half') {
                    this.value['units'] = newVal * 4;
                } else if (this.unitType === 'hour') {
                    this.value['units'] = newVal * 1;
                } else {
                    this.value['units'] = newVal * 8;
                }
                this.$v.value.units.$touch();
                this.$emit('input', this.value);
            },
            unitType: function (newVal) {
                if (newVal === 'half') {
                    this.value['units'] = this.unitCount * 4;
                } else if (newVal === 'hour') {
                    this.value['units'] = this.unitCount;
                } else {
                    this.value['units'] = this.unitCount * 8;
                }
                this.$v.value.units.$touch();
                this.$emit('input', this.value);
            }
        },
        data() {
            return {
                searchPeriod: 'period',
                range: [],
                unitCount: 1,
                unitType: 'day',
                unitTypeOptions: [
                    {
                        id: 'hour',
                        label: 'Stunde/n',
                    },
                    {
                        id: 'half',
                        label: 'Vormittag od. Nachmittag',
                    },
                    {
                        id: 'day',
                        label: 'Ganzer Tag',
                    }
                ],
            }
        },
    }
</script>

<template>
    <div>
        <data-table-and-edit
            :offer="value"
        />

        <div class="flex justify-content-between">
            <div class="block"></div>
            <div class="block">
                <router-link :to="'/offers/' + value.id">
                    <Button
                        label="Zur Dienstleistung"
                        class="p-button-sm p-button-secondary cursor-pointer"
                    />
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button/Button";
    import Value from "@/mixin/Value";
    import OrganizationUpdateForm from "@/components/data/matchingEntity/OrganizationUpdateForm";
    import DataTableAndEdit from "@/components/data/offer/availability/DataTableAndEdit";

    export default {
        components: {DataTableAndEdit, OrganizationUpdateForm, Button},
        mixins: [Value],
        methods: {},
    }
</script>
